import { Box, Text } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import config from '../../../utils/config';
import WavEncoder from 'wav-encoder';
import ReactAudioPlayer from 'react-audio-player';

const SelectedAudio = ({
	item,
	sessionId,
	onlyAudio,
	bufferData,
}: {
	item: any;
	sessionId: string | undefined;
	onlyAudio: boolean;
	bufferData: any;
}) => {
	const buffer = useRef<any>(null);
	const [audioSrc, setAudioSrc] = React.useState<string>('');

	const fetchAudioFile = async () => {
		if (bufferData) {
			buffer.current = bufferData;
			return;
		}

		if (!sessionId) {
			return;
		}
		if (buffer.current) {
			return;
		}

		let response;

		try {
			const url = `${config.qa_api.url}/files/getFile?type=recordings&id=${sessionId}`;
			response = await fetch(url, {
				method: 'GET',
				credentials: 'include',
			});
			if (response.status !== 200) {
				return;
			}
		} catch (error) {
			return;
		}

		const audioContext = new window.AudioContext();
		const arrayBuffer = await response?.arrayBuffer();
		const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
		buffer.current = audioBuffer;
	};

	const sliceAudioBuffer = (secondStart: number, duration: number) => {
		const audioBuffer = buffer.current;
		const sampleRate = audioBuffer.sampleRate;
		const startSample = Math.floor(secondStart * sampleRate);
		const endSample = Math.floor((secondStart + duration) * sampleRate);

		const numberOfChannels = audioBuffer.numberOfChannels;
		const length = endSample - startSample;

		const audioContext = new window.AudioContext();
		const slicedBuffer = audioContext.createBuffer(numberOfChannels, length, sampleRate);

		for (let channel = 0; channel < numberOfChannels; channel++) {
			const originalData = audioBuffer.getChannelData(channel);
			const slicedData = slicedBuffer.getChannelData(channel);
			slicedData.set(originalData.slice(startSample, endSample));
		}

		return slicedBuffer;
	};

	const bufferToBlob = async (audioBuffer: any) => {
		const channelData = [];
		for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
			channelData.push(audioBuffer.getChannelData(i));
		}

		const wavData = await WavEncoder.encode({
			sampleRate: audioBuffer.sampleRate,
			channelData: channelData,
		});

		return new Blob([new Uint8Array(wavData)], { type: 'audio/wav' });
	};

	const processAndPlayAudio = async (secondStart: number, duration: number) => {
		const slicedBuffer = sliceAudioBuffer(secondStart, duration);
		const audioBlob = await bufferToBlob(slicedBuffer);
		const blobUrl = URL.createObjectURL(audioBlob);
		setAudioSrc(blobUrl);
	};

	const getFromAttributes = (attributes: any, key: string) => {
		const value = attributes.find((attribute: any) => {
			return attribute.key === key;
		})?.value;
		return value || '';
	};

	const confidence = item && getFromAttributes(item.attributes, 'confidence');
	const type = item?.type;
	const subtype = item?.subtype;
	const resultText = item && getFromAttributes(item.attributes, 'resultText');

	useEffect(() => {
		fetchAudioFile();
	}, []);

	useEffect(() => {
		if (item && type === 'transcription_sentence' && (subtype === 'voice' || onlyAudio)) {
			if (!buffer.current) {
				return;
			}
			const waveStart = parseFloat(getFromAttributes(item.attributes, 'waveStart'));
			const duration = item.length;
			processAndPlayAudio(waveStart, duration);
		}
	}, [item]);

	if (onlyAudio) {
		return (
			<Box>
				{item && (
					<Box width={'500px'} mb={5}>
						<ReactAudioPlayer src={audioSrc} controls />
					</Box>
				)}
			</Box>
		);
	}

	return (
		<Box>
			{item && (
				<Box
					mt={3}
					borderWidth={1}
					borderColor={'#ccc'}
					p={5}
					width={'500px'}
					backgroundColor={'#ffffff'}
				>
					<Text>
						<Text fontWeight={'bold'}>Type:</Text>
						{item.type}
					</Text>
					<Text mt={3}>
						<Text fontWeight={'bold'}>Text:</Text>
						{item.text}
					</Text>
					{resultText && (
						<Text mt={3}>
							<Text fontWeight={'bold'}>Result text:</Text>
							{resultText}
						</Text>
					)}
					{confidence && (
						<Text mt={3}>
							<Text fontWeight={'bold'}>Confidence:</Text>
							{confidence}
						</Text>
					)}
					{type === 'transcription' && subtype === 'voice' && (
						<Text mt={3}>
							<Text fontWeight={'bold'}>Sample:</Text>
							<ReactAudioPlayer src={audioSrc} controls />
						</Text>
					)}
				</Box>
			)}
		</Box>
	);
};

export default SelectedAudio;
